












































































import { Component, Vue } from "vue-property-decorator";
import SendIcon from "vue-material-design-icons/Send.vue";
import CloudDownloadIcon from "vue-material-design-icons/CloudDownload.vue";
import LockIcon from "vue-material-design-icons/Lock.vue";
import CellphoneIcon from "vue-material-design-icons/Cellphone.vue";
import NinetyNineLogo from "../components/base/NinetyNineLogo.vue";

@Component({
  components: {
    SendIcon,
    CloudDownloadIcon,
    LockIcon,
    CellphoneIcon,
    NinetyNineLogo,
  },
})
export default class VerifyYourAccount extends Vue {
  verify_list = [
    {
      icon: "SendIcon",
      text: "ContinueFromApp_List_Item1",
    },
    {
      icon: "CloudDownloadIcon",
      text: "ContinueFromApp_List_Item2",
    },
    {
      icon: "LockIcon",
      text: "ContinueFromApp_List_Item3",
    },
    {
      icon: "CellphoneIcon",
      text: "ContinueFromApp_List_Item4",
    },
  ];

  resentCodeMsg = "";
  resentCodeCountdown = 10;

  sendSMS(): void {
    this.$store.dispatch("register/sendDownloadSMS");
  }

  resendSMS(): void {
    this.sendSMS();
    this.showSmsSentMessage();
  }

  showSmsSentMessage(): void {
    const TIMEOUT = 10000;
    this.resentCodeMsg = this.$t("SignUp_SMS_Resent") as string;
    setTimeout(() => (this.resentCodeMsg = ""), TIMEOUT);
  }
}
