import { render, staticRenderFns } from "./ContinueFromApp.vue?vue&type=template&id=7f83f383&scoped=true&"
import script from "./ContinueFromApp.vue?vue&type=script&lang=ts&"
export * from "./ContinueFromApp.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f83f383",
  null
  
)

export default component.exports